import React from 'react';
import useAuth from '../../components/use-auth';
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import StoreForm from '../../components/store-form';

const AddStorePage = ({path}) => {
  const auth = useAuth();
  return (
    <Layout auth={auth} path={path}>
      <SEO title="Add store" />
      { auth && (
        <StoreForm action="add" />
      )}
    </Layout>
  )
}

export default AddStorePage;
